<template>
   <div class="vs-row">
        <template lang="html">
          <div>
            <vx-card actionable class="cardx" title="EWB CMA">
              <vs-table  max-items="10" :data="users">
                  <template slot="thead">
                      <vs-th >
                      Batch
                      </vs-th>
                      <vs-th>
                      Batch Date
                      </vs-th>
                      <vs-th>
                      Subject Started
                      </vs-th>
                      <vs-th>
                      Enrollment Count
                      </vs-th>
                      <vs-th>
                      Fees Due
                      </vs-th>
                      <vs-th>
                      Defaulters
                      </vs-th>
                      <vs-th>
                      Books Pending
                      </vs-th>
                  </template>

                  <template slot-scope="{data}">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                      <vs-td  :data="data[indextr].batch" >
                          {{data[indextr].batch}}
                      </vs-td>

                       <vs-td :data="data[indextr].batch_date">
                          {{data[indextr].batch_date}}
                      </vs-td>

                       <vs-td :data="data[indextr].subject_started">
                           {{data[indextr].subject_started}}
                      </vs-td>

                      <vs-td :data="data[indextr].enrollment_count">
                           {{data[indextr].enrollment_count}}
                      </vs-td>

                      <vs-td :data="data[indextr].fees_due">
                           {{data[indextr].fees_due}}
                      </vs-td>

                      <vs-td :data="data[indextr].defaulters">
                           {{data[indextr].defaulters}}
                      </vs-td>

                      <vs-td :data="data[indextr].books_pending">
                           {{data[indextr].books_pending}}
                      </vs-td>

                      </vs-tr>
                  </template>
                </vs-table>  
            </vx-card>
          </div>  
        </template>   
             
    </div>
   

</template>
<script>
// import VueApexCharts from vue-apexcharts
export default {
    data:()=>({
    users:[
      {
        "batch": "CPA-M7-43",
        "batch_date": "21-Apr-2019",
        "subject_started": "AUD",
        "enrollment_count": "25",
        "fees_due": "3|60,000",
        "defaulters": "3|30,000",
        "books_pending":"3",
      },
      {
        "batch": "CPA-M7-42",
        "batch_date": "17-Feb-2019",
        "subject_started": "REG",
        "enrollment_count": "13",
        "fees_due": "2|40,000",
        "defaulters": "2|20,000",
        "books_pending":"4",
      },
      {
        "batch": "CPA-M7-41",
        "batch_date": "2-Dec-2018",
        "subject_started": "BEC",
        "enrollment_count": "24",
        "fees_due": "10|2,00,000",
        "defaulters": "4|40,000",
        "books_pending":"17",
      },
      {
        "batch": "CPA-M7-40",
        "batch_date": "9-Sep-2018",
        "subject_started": "FAR",
        "enrollment_count": "18",
        "fees_due": "0|0",
        "defaulters": "0|0",
        "books_pending":"0",
      },
      {
        "batch": "CPA-M7-39",
        "batch_date": "8-Jul-2018",
        "subject_started": "AUD",
        "enrollment_count": "15",
        "fees_due": "6|1,20,000",
        "defaulters": "7|70,000",
        "books_pending":"14",
      },
      {
        "batch": "CPA-M7-38",
        "batch_date": "27-May-2018",
        "subject_started": "BEC",
        "enrollment_count": "17",
        "fees_due": "7|1,40,000",
        "defaulters": "5|50,000",
        "books_pending":"6",
      },
      {
        "batch": "CPA-M7-37",
        "batch_date": "11-Mar-2018",
        "subject_started": "FAR",
        "enrollment_count": "15",
        "fees_due": "5|1,00,000",
        "defaulters": "8|80,000",
        "books_pending":"5",
      },
      {
        "batch": "CPA-M7-36",
        "batch_date": "17-Dec-2017",
        "subject_started": "AUD",
        "enrollment_count": "10",
        "fees_due": "0|0",
        "defaulters": "0|0",
        "books_pending":"0",
      },
      {
        "batch": "CPA-M7-35",
        "batch_date": "5-Nov-2017",
        "subject_started": "REG",
        "enrollment_count": "11",
        "fees_due": "2|40,000",
        "defaulters": "2|20,000",
        "books_pending":"2",
      },
      {
        "batch": "CPA-M7-34",
        "batch_date": "17-Sep-2017",
        "subject_started": "BEC",
        "enrollment_count": "16",
        "fees_due": "4|80,000",
        "defaulters": "6|60,000",
        "books_pending":"9",
      },
      {
        "batch": "CPA-M7-33",
        "batch_date": "2-Jul-2017",
        "subject_started": "FAR",
        "enrollment_count": "17",
        "fees_due": "6|1,20,000",
        "defaulters": "7|70,000",
        "books_pending":"8",
      },
      {
       "batch": "CPA-M7-32",
        "batch_date": "23-Apr-2017",
        "subject_started": "AUD",
        "enrollment_count": "9",
        "fees_due": "4|80,000",
        "defaulters": "3|30,000",
        "books_pending":"4",
      },
      {
        "batch": "CPA-M7-31",
        "batch_date": "5-Feb-2017",
        "subject_started": "REG",
        "enrollment_count": "2",
        "fees_due": "0|0",
        "defaulters": "0|0",
        "books_pending":"0",
      }
    ],
  }),
}
</script>
<style>

</style>

